@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: var(--font-anthro) var(--font-roc);
  font-weight: 400;
}

body {
  overflow-y: scroll;
}

input {
  outline: none !important;
}

.markdown h2 {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.markdown h3 {
  font-size: 1.0rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.cl-userButtonPopoverFooter {
  display: none;
}